import "./box.css";
import { lightBoxData } from "../portfolioData"

function Box({ setBoxId, setLightBoxDisplay }) {
    return (
        <div className="boxContainer">
            {lightBoxData.map(box => (
                <div key={box.lightBoxId} className="box" onClick={() => {
                    setLightBoxDisplay(false);
                    setBoxId(box.lightBoxId);
                    console.log(box.lightBoxId)
                }}>
                    <img className="square_1" src={`/imgs/boxImages/${box.image_1}`} alt="" />
                    <img className="square_2" src={`/imgs/boxImages/${box.image_2}`} alt="" />
                </div>
            ))
            }
        </div >
    )
}

export default Box

