import "./about.css";
import Particles from 'react-particles-js';
function About() {
    return (
        <div className="about">
            <Particles />
        </div>
    )
}

export default About
