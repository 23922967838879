import "./caseStudy.css"

function CaseStudy() {
    return (
        <div className="caseStudy">
            caseStudy
        </div>
    )
}

export default CaseStudy
