import './home.css';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
// import Particles from 'react-particles-js';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


import Typist from 'react-typist';

function Home() {

    const [count, setCount] = useState(1);

    useEffect(() => {
        console.log("Count: " + count);
        setCount(1);
    }, [count]);


    return (
        <div className="home">
            {/* <Particles className="particles" /> */}
            <div className="homeContainer">
                <div className="slideContainer">
                    <img className="mainSlide" src="imgs/main-slide-2.png" alt="" />
                    <img className="mobileMainSlide" src="imgs/main-slide-mobile-meidum.png" alt="" />
                </div>
                <div className="infoContainer">
                    <div className='nameContainer'>
                        <h1 className="mainName">Emad Saeed</h1>
                        <h2 className="mainTitle">Software Engineer</h2>
                    </div>

                    <div>
                        {count ? (
                            <Typist avgTypingDelay={100} onTypingDone={() => setCount(0)}>
                                <span className="slogan">It is all about software development...</span>
                                <Typist.Backspace count={39} delay={1800} />
                                <span className="slogan">Unrivaled creativity is just the beginning...</span>
                                <Typist.Backspace count={45} delay={1800} />
                                <span className="slogan">Deliver results that exceed expectations!</span>
                                <Typist.Backspace count={41} delay={1800} />
                                
                            </Typist>
                        ) : (
                            ""
                        )}
                    </div>

                    {/* <h1 className="slogan">Get the idea done from concept to implementation!</h1> */}
                    <div className='contactMeBtn' ><ArrowDownwardIcon className='downArrow' /><NavLink to='/contact'>Contact me!</NavLink></div>
                </div>
            </div>
        </div >
    )
}

export default Home
