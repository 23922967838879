export const lightBoxData = [
    {
        lightBoxId: 1,
        image_1: "amazon-dpd-sq-1.jpg",
        image_2: "amazon-dpd-sq-2.jpg",
        projectName: "amazon-dpd Shipping Builder",
        projectLinkDisplayed: "block", // none or block
        projectLink: "https://amazon-dpd.emadsaeed.com/",
        descriptionDisplayed: "block", // none or block
        projectDescription: "Full-stack web application for building dpd shipping labels for amazon-vendors.",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "Automate dpd shipping labels process.",
            "Auto collecting products' data (Fabric, Harmonised Code, Item Origin, Unit Value, etc.",
            "Autofill delivery notes.",
            "Auto calculates total delivery weight.",
            "Manageable address-book",
            "Multiple APIs integration.",
            "Technology used: React.js, Redux, MUI, Node.js, Express, MySQL."
        ],
        featursDisplayed: "",   // none or block
        screenshots: [],
        screenshotsDisplayed: "none" // // none or block or block
    },
    {
        lightBoxId: 2,
        image_1: "Places-sq-1.jpg",
        image_2: "Places-sq-2.jpg",
        projectName: "Places itinerary generator",
        projectLinkDisplayed: "", // none or block
        projectLink: "https://places.itinerary.emadsaeed.com/",
        descriptionDisplayed: "noblockne", // none or block
        projectDescription: "Introducing the ultimate travel planner! With just a few taps, you can input your holiday destination, how long you're visiting, and your budget. Utilising the power of the OpenAI API to generate a personalised itinerary tailored to you and Google Places API to show you stunning images of your destination.",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "Integration with chatgpt OpenAi API",
            "Integration with Google Places API",
            "Autocomplete search for all the cities around the world.",
            "User get a response with suggestions for their itinerary",
            "Get images for destination city.",
            "User searches saved for another go",
        ],
        featursDisplayed: "",   // none or block
        screenshots: [],
        screenshotsDisplayed: "none" // // none or block or block
    },

    {
        lightBoxId: 3,
        image_1: "NexTecs-sq-1.jpg",
        image_2: "NexTecs-sq-2.jpg",
        projectName: "NexTecs orders dashboard mockup",
        projectLinkDisplayed: "", // none or block
        projectLink: "https://nextecs.emadsaeed.com/",
        descriptionDisplayed: "none", // none or block
        projectDescription: "Dashboard mockup for NexTecs web-application",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "Create, update, delete and search orders.",
            "Create subscription and membership",
            "Create reports.",
            "Technology used: React.js, TypeScript.",
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },
    {
        lightBoxId: 4,
        image_1: "Alexmed-sq-1.jpg",
        image_2: "Alexmed-sq-2.jpg",
        projectName: "Alexmed psychiatrist clinic - website.",
        projectLinkDisplayed: "", // none or block
        projectLink: "https://alexmed.co.uk/",
        descriptionDisplayed: "none", // none or block
        projectDescription: "A responsive website for Alexmed psychiatrist clinic.",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "Responsive web-design",
            "Protected forms | Google reCAPTCHA",
            "Custom Business Email | Google Workspace",
            "Website secured with a digital certificate"
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },
    {
        lightBoxId: 5,
        image_1: "homelessHouse-sq-1.jpg",
        image_2: "homelessHouse-sq-2.jpg",
        projectName: "HomelessHouse Shop - website.",
        projectLinkDisplayed: "none", // none or block
        projectLink: "https://shop.homelesshouse.emadsaeed.com/",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "E-commerce",
            "Responsive web-design",
            "Automatic stock update via API integration",
            "Sending orders automatically via API integration",
            "Integration with the business ERP system"
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },

    {
        lightBoxId: 6,
        image_1: "cosmeticGynaecology-sq-1.jpg",
        image_2: "cosmeticGynaecology-sq-2.jpg",
        projectName: "Cosmetic Gynaecology clinic - website.",
        projectLinkDisplayed: "none", // none or block
        projectLink: "https://cosmetic-gynaecology.art-media.uk/",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "Responsive web-design",
            "Protected forms | Google reCAPTCHA",
            "Custom Business Email | Google Workspace",
            "Website secured with a digital certificate",
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },

    {
        lightBoxId: 7,
        image_1: "impogo-sq-1.jpg",
        image_2: "impogo-sq-2.jpg",
        projectName: "impogo - website.",
        projectLinkDisplayed: "none", // none or block
        projectLink: "https://impogo.emadsaeed.com",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "E-commerce",
            "Responsive web-design",
            "Automatic stock update via API integration",
            "Sending orders automatically via API integration",
            "Integration with the business ERP system",
            "Full Catalogue more than 60000 line-items"
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },

    {
        lightBoxId: 8,
        image_1: "sloganTee-sq-1.jpg",
        image_2: "sloganTee-sq-2.jpg",
        projectName: "Slogantee - website.",
        projectLinkDisplayed: "none", // none or block
        projectLink: "https://slogantee.emadsaeed.com",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "E-commerce",
            "Responsive web-design",
            "Customer can create their own design or QR code to be printed on the garment"
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },


    {
        lightBoxId: 9,
        image_1: "sanGiorgio-sq-1.jpg",
        image_2: "sanGiorgio-sq-2.jpg",
        projectName: "San Giorgio Tour - website.",
        projectLinkDisplayed: "none", // none or block
        projectLink: "https://sg.art-media.uk/",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "Responsive web-design",
            "Protected forms | Google reCAPTCHA",
            "Integration with www.viator.com",
            "Ticket booking",
            "Custom Business Emails",
            "Website secured with a digital certificate",
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },
    {
        lightBoxId: 10,
        image_1: "northernCouture-sq-1.jpg",
        image_2: "northernCouture-sq-2.jpg",
        projectName: "Northern Quarter Order Application",
        projectLinkDisplayed: "", // none or block
        projectLink: "#",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "Responsive web-design",
            "Protected forms | Google reCAPTCHA",
            "Custom Business Email | Google Workspace",
            "Website secured with a digital certificate"
        ],
        featursDisplayed: "",   // none or block
        screenshots: [
            "imgs/alexmed/alexmed-01.jpg",
            "imgs/alexmed/alexmed-02.jpg",
            "imgs/alexmed/alexmed-03.jpg",
            "imgs/alexmed/alexmed-04.jpg",
        ],
        screenshotsDisplayed: "none" // // none or block or block
    },
    {
        lightBoxId: 11,
        image_1: "tiptotoePodiatry-sq-1.jpg",
        image_2: "tiptotoePodiatry-sq-2.jpg",
        projectName: "Tiptotoe podiatry clinic - website.",
        projectLinkDisplayed: "block", // none or block
        projectLink: "https://tiptotoe.emadsaeed.com",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "Responsive web-design",
            "Protected forms | Google reCAPTCHA",
            "Custom Business Email",
            "Website secured with a digital certificate",
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },
    {
        lightBoxId: 12,
        image_1: "fabradiointernational-sq-1.jpg",
        image_2: "fabradiointernational-sq-2.jpg",
        projectName: "Fab Radio International clothing shop - website.",
        projectLinkDisplayed: "none", // none or block
        projectLink: "https://fabradiointernational-clothing.emadsaeed.com/",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "E-commerce",
            "Responsive web-design",
            "Automatic stock update via API integration",
            "Sending orders automatically via API integration",
            "Integration with the business ERP system"
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },
    {
        lightBoxId: 13,
        image_1: "DW-sq-1.jpg",
        image_2: "DW-sq-2.jpg",
        projectName: "Diouani Welding.com - website.",
        projectLinkDisplayed: "block", // none or block
        projectLink: "https://dw.emadsaeed.com/",
        descriptionDisplayed: "none", // none or block
        projectDescription: "",
        tecUsedDisplayed: "none", // none or block
        tecUsed: [
            {
                tecId: 1,
                tecIcon: "imgs/tec-imgs/html-css-js.jpg",
                tecName: "html-css-js",
            },
            {
                tecId: 2,
                tecIcon: "imgs/tec-imgs/php.jpg",
                tecName: "php",
            },
            {
                tecId: 3,
                tecIcon: "imgs/tec-imgs/wp2.jpg",
                tecName: "Wordpress",
            },
            {
                tecId: 4,
                tecIcon: "imgs/tec-imgs/Mysql.jpg",
                tecName: "Mysql",
            },

        ],

        featurs: [
            "CMS",
            "E-commerce",
            "Content on French",
            "Responsive web-design",
            "Protected forms | Google reCAPTCHA",
            "Custom Business Email",
            "Website secured with a digital certificate",
        ],
        featursDisplayed: "",   // none or block
        screenshots: [

        ],
        screenshotsDisplayed: "none" // // none or block or block
    },
]